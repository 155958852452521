// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // oldUrlDev: 'https://api.dev.icheck.vn',
  // oldUrlDev: 'https://apiv2.dev.icheck.vn/api/business/public/loyalty',
  // urlDev: 'https://apiv2.dev.icheck.vn',
  urlDev: 'https://sellout-loyalty-api.dev.icheck.vn',
  public: '/api/business/public/loyalty/',
  auth: '/api/business/loyalty/',
  urlTemplateMKT: 'http://cd-dev.qcheck.vn/?q=',
  openAppiCheck: 'https://icheck.page.link/home',
  openAppiCheckLucky: 'icheck://icheck_missions',
  openAppiCheckReward: 'icheck://my_rewards',
  isVersionViettel: false,
  phongvu: {
    isEnable: true,
    userIds: [71],
    wheelItemColor: [['#E65491', '#216BFF'], ['#FFFFFF', '#47C5FF']],
    wheelInnerStroke: ['#908e96', '#FFFFFF'],
    wheelCoverStroke: ['#c8d6f4', '#4c8dff', '#36abe0', '#ffffff'],
    landingUrl: 'https://phongvu-landing.dev.icheck.vn'
  },
  userIcheck: {userId: [364]},
  tracking_id_web: '5eac7a04-7565-4768-9d9c-b43293f77250',
  tracking_id_mobile: 'ddebc619-4f17-4a77-b091-8d99ed64b7b9',
  versionCDN: '1.0.147'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
